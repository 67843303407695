<div [ngClass]='containerClass' class='layout-wrapper'>
  <app-background-video />
  @if (!hideLayout()) {
    <app-navbar />
  } @else {
    <img src="assets/layout/images/{{layoutService.config().colorScheme === 'light' ? 'logo-black' : 'logo-white'}}.svg"
         alt='logo' class='mt-5 ml-6' />
  }
  <p-toast />
  <router-outlet />
  @if (!hideLayout()) {
    <app-footer />
  }
  <div class='layout-mask'></div>
</div>
