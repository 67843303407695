import { Component, ElementRef, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { ActivatedRoute, EventType, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from '@/layout/services/layout.service';
import { AuthService } from '@/pages/auth/services/auth.service';
import { environment } from '@environments/environment';
import appVersion from '../../../../../app_version.json';

interface NavbarItem {
  id: string;
  label: string;
  routerLink: string[];
  active?: boolean;
  icon: string;
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
})
export class NavbarComponent implements OnInit {
  version = 'v' + appVersion.version + '-' + environment.appEnv;
  profileMenuItems: MenuItem[];
  navbarItems: NavbarItem[];
  title: string;

  @ViewChild('menuButton') menuButton!: ElementRef;
  @ViewChild('navbarMenuButton') navbarMenuButton!: ElementRef;
  @ViewChild('navbarMenu') menu!: ElementRef;

  readonly sidebarMenuVisible: WritableSignal<boolean> = signal<boolean>(false);

  constructor(
    private _activatedRoute: ActivatedRoute,
    protected layoutService: LayoutService,
    private _translate: TranslateService,
    private _authService: AuthService,
    private _router: Router,
  ) {
  }

  ngOnInit(): void {
    this.navbarItems = [
      // {
      //   id: 'dashboard',
      //   label: this._translate.instant('DASHBOARD.TITLE'),
      //   routerLink: ['/dashboard'],
      //   icon: 'pi pi-home',
      // },
      {
        id: 'offers',
        label: this._translate.instant('OFFERS.TITLE'),
        routerLink: ['/offers'],
        icon: 'pi pi-list',
      },
      {
        id: 'chat',
        label: this._translate.instant('CHAT.TITLE'),
        routerLink: ['/chat'],
        icon: 'pi pi-comments',
      },
    ];

    this.profileMenuItems = [
      // TODO add back once there is i18n support
      // {
      //   label: this._translate.instant('LAYOUT.NAVBAR.LANGUAGE'),
      //   icon: 'pi pi-language',
      //   command: () => this._translationService.toggleLanguage(),
      // },
      {
        label: this._translate.instant('LAYOUT.NAVBAR.LOGOUT'),
        icon: 'pi pi-power-off',
        command: () => this._authService.logout(),
      },
    ];

    this._router.events.subscribe(event => {
      if (event.type === EventType.NavigationEnd) {
        this.updateActiveNavbarItems();
      }
    });

    // Initial call to set active navbar item on load
    this.updateActiveNavbarItems();
  }

  updateActiveNavbarItems(): void {
    this._activatedRoute.firstChild?.data.subscribe(data => {
      // Active navbar item
      this.navbarItems?.forEach(item => {
        item.active = data['navbarItem'] === item.id;
      });

      // Title
      this.title = this._translate.instant(data['title']);
    });
  }

  navigate(routerLink: string[]): void {
    this._router.navigate(routerLink).then(r => {
      !r && console.error('Navigation failed in navbar.component/navigate');
    });
  }

  getUsername(): string {
    const user = this._authService.getUser();

    let username = '';
    if (user?.firstname) {
      username += user.firstname + ' ';
    }
    if (user?.lastname) {
      username += user.lastname;
    }

    return username;
  }

  onSidebarMenuToggle(): void {
    this.sidebarMenuVisible.update(prev => !prev);
  }
}
