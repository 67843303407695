import { Component, signal, WritableSignal, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Media } from '@/common/models';
import { AuthService } from '@/pages/auth/services/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  readonly companyMedia: WritableSignal<Media | null | undefined> = signal<Media | null | undefined>(null);

  constructor(
    private _authService: AuthService,
    private _router: Router,
  ) {
  }

  ngOnInit(): void {
    this.companyMedia.set(this._authService.getUser()?.entity.media);
  }

  onClick(): void {
    this._router.navigate(['/company/edit']).then(r => {
      !r && console.error('Navigation failed in footer.component/onClick');
    });
  }
}

